import { Button, message, Modal, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { httpGet, httpPost } from '../../services/http'
import { Session } from '../../types/session'

const VerifyEmailContainer = () => {
    let location = useLocation()
    const [status, setStatus] = useState<boolean | null>(null)

    useEffect(() => {
        const verifyToken = new URLSearchParams(location.search).get('token')
        if (!verifyToken) return
        httpGet<Session>('/auth/user')
            .then((res) => {
                if (res.data.isLoggedIn) {
                    httpPost<Response>(`/auth/verify-email/${verifyToken}`)
                        .then((r) => {
                            setStatus(true)
                        })
                        .catch((error) => {
                            if (error.data.detail) {
                                message.error(error.data.detail)
                            } else {
                                message.error(error.toString())
                            }
                            setStatus(false)
                        })
                } else {
                    Modal.info({
                        title: 'Not logged in',
                        content: 'You have to be logged in, in order to verify your E-mail',
                        okText: 'Log In',
                        onOk: () => {
                            navigate(
                                `/login/?redirect_url=${location.pathname}${location.search}`,
                                {replace: true},
                            )
                        },
                        onCancel: () => {
                            setStatus(false)
                        },
                    })
                }
            })
            .catch((error) => {
                navigate('/error/', {replace: true})
            })
    }, [location])

    const goBack = () => {
        navigate('/app/')
    }

    return (
        <div className="login-background">
            <div className="login-background-inner">
                <div className="login-container">
                    <div>
                        <Link to="/" replace>
                            <div className="logoImgLarge" />
                        </Link>
                        {(status === null && <h2>Verifying E-mail...</h2>) ||
                            (status === true && (
                                <Result
                                    status="success"
                                    title="Successfully Verified E-mail!"
                                    subTitle={
                                        'You can now receive E-mail and' +
                                        ' login with your new address'
                                    }
                                    extra={[
                                        <Button type="primary" key="goback" onClick={goBack}>
                                            OK
                                        </Button>,
                                    ]}
                                />
                            )) ||
                            (status === false && (
                                <Result
                                    status="warning"
                                    title={
                                        'There are some problems with changing your email.' +
                                        ' Make sure that you are logged in when' +
                                        ' clicking the link.' +
                                        ' Or try requesting an E-mail change again.'
                                    }
                                    extra={[
                                        <Button type="primary" key="goback" onClick={goBack}>
                                            Go back to App
                                        </Button>,
                                    ]}
                                />
                            ))}
                    </div>
                </div>
                <br />
            </div>
        </div>
    )
}

export default VerifyEmailContainer
